import { Box, Button, CircularProgress, Popover, TextField, Typography } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

import React, { useState } from "react";
import { api } from "../../config/api";
import { useStore } from "../../store";

export default function SearchOrder() {
  const setCurrentOrder = useStore((state) => state.setCurrentOrder);

  const [search, setSearch] = useState(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const code = `${year.toString().slice(1)}-${month.toString().padStart(3, "0")}-`;
    return code;
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [searching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-seacrh" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotFound(false);
    setSearch((prev) => prev.split("-").slice(0, 2).join("-") + "-");
    setAnchorEl(event.currentTarget);
  };

  const handleSearch = () => {
    setNotFound(false);
    setSearching(true);
    api
      .get(`/orders/search-code/${search}`)
      .then((response) => {
        setCurrentOrder(response.data);
      })
      .catch((error) => {
        setNotFound(true);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  return (
    <>
      <Button style={{ marginLeft: 15 }} startIcon={<SearchOutlined />} color="primary" onClick={handleClick}>
        Buscar pedido
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ width: "400px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          style={{
            padding: 20,
          }}
        >
          <Typography variant="button">Código do pedido</Typography>

          <Box display="flex" alignItems={"center"}>
            <TextField
              style={{ width: "200px" }}
              variant="outlined"
              size="small"
              value={search}
              autoFocus
              disabled={searching}
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <CircularProgress
              size={searching ? 20 : 0}
              style={{
                marginLeft: searching ? "10px" : "0",
              }}
            />
          </Box>

          <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
            {notFound && "Pedido não encontrado"}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
