import { Box, CircularProgress, Slider, Typography } from "@material-ui/core";
import React from "react";
import { useStore } from "../../../store";
import AcceptAll from "./AcceptAll";

export default function BoardHeader() {
  const defaultTime = useStore((state) => state.defaultTime);
  const updateDefaultTime = useStore((state) => state.updateDefaultTime);

  const historyHours = useStore((state) => state.historyHours);
  const setHistoryHours = useStore((state) => state.setHistoryHours);

  const beepVolume = useStore((state) => state.beepVolume);
  const setBeepVolume = useStore((state) => state.setBeepVolume);
  const currentOrder = useStore((state) => state.currentOrder);

  const [hours, setHours] = React.useState<number | number[]>(historyHours);
  const isSearchingByHours = useStore((state) => state.isSearchingByHours);

  const [beepVolume_, setBeepVolume_] = React.useState<number | number[]>(beepVolume);

  const handleChangeDefaultTime = (event: any, newValue: number | number[]) => {
    event.preventDefault();
    event.stopPropagation();
    updateDefaultTime(newValue as number[]);
  };

  const handleChangeHistoryHours = (event: any, newValue: number | number[]) => {
    event.preventDefault();
    event.stopPropagation();
    setHours(newValue as number[]);
  };

  const handleChangeVolume = (event: any, newValue: number | number[]) => {
    event.preventDefault();
    event.stopPropagation();
    setBeepVolume_(newValue as number[]);
  };

  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
        <Box
          className="BoardHeaderSlider"
          style={{
            display: currentOrder ? "none" : "",
            width: 200,
          }}
        >
          <AcceptAll />
        </Box>
        <Box className="BoardHeaderSlider">
          <Slider
            value={defaultTime}
            step={5}
            onChange={handleChangeDefaultTime}
            valueLabelDisplay="off"
            min={5}
            max={300}
          />

          <Typography variant="caption">
            {defaultTime[0]}min à {defaultTime[1]}min
          </Typography>
        </Box>
      </Box>

      <Box
        className="BoardHeaderSlider"
        style={{
          paddingRight: 20,
        }}
        display="flex"
      >
        <Box
          style={{
            marginRight: 10,
          }}
        >
          <Slider
            value={beepVolume_}
            step={1}
            onChange={handleChangeVolume}
            valueLabelDisplay="off"
            min={0}
            max={9}
            onChangeCommitted={() => {
              setBeepVolume(beepVolume_ as number[]);
            }}
          />

          <Typography variant="caption">Volume Beep</Typography>
        </Box>

        <CircularProgress
          size={20}
          style={{
            opacity: isSearchingByHours ? 1 : 0,
            marginTop: -25,
            marginRight: 10,
          }}
        />

        <Box>
          <Slider
            value={hours}
            step={1}
            disabled={isSearchingByHours}
            onChange={handleChangeHistoryHours}
            valueLabelDisplay="off"
            min={1}
            max={24}
            onChangeCommitted={() => {
              setHistoryHours(hours as number[]);
            }}
          />

          <Typography variant="caption">Exibir pedidos de {hours.toString().padStart(2, "0")}h atrás</Typography>
        </Box>
      </Box>
    </Box>
  );
}
