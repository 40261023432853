import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import React, { ChangeEvent, FormEvent, useRef, useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { api } from "../../../config/api";
import { formatPrice } from "../../../config/price";
import { useStore } from "../../../store";
import { ItemDetail } from "../../Boards/OrderDetail/OrderDetail";
import "./OrderForm.css";

function ignoreAccentsAndCases(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export const OrderForm: React.FC<{ show; onClose; order; disabled }> = ({ show, onClose, order, disabled }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const autoC = useRef(null);

  const [customer, setCustomer] = useState(order.customer);
  const [phone, setPhone] = useState(order.phone);

  const [direction, setDirection] = useState(order.direction);
  const [number, setNumber] = useState(order.number);
  const [addressComplement, setAddressComplement] = useState(order.addressComplement);
  const [addressNotes, setAddressNotes] = useState(order.addressNotes);
  const [neighbourhood, setNeighbourhood] = useState(order.neighbourhood?.name);

  const [notes, setNotes] = useState(order.notes);
  const [paymentMethod, setPaymentMethod] = useState(order.paymentMethod.id);
  const [shippingCost, setShippingCost] = useState(order.shippingCost / 100);
  const [subtotal, setSubtotal] = useState(order.subtotal);
  const [total, setTotal] = useState(order.total);
  const [discountAmount, setDiscountAmount] = useState(order?.discountAmount);
  const [moneyChange, setMoneyChange] = useState(order.moneyChange / 100);
  const setProducts = useStore((state) => state.setProducts);

  const paymentMethods = useStore((state) => state.paymentMethods);
  const products = useStore((state) => state.products);

  const setDialog = useStore((state) => state.setDialog);

  const [items, setItems] = useState(JSON.parse(JSON.stringify(order.items)));

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const closeModalEvent = () => {
      document.removeEventListener("close-modal", closeModalEvent);
      onClose();
    };

    document.addEventListener("close-modal", closeModalEvent);

    api.get("/orders/products").then((response) => {
      setProducts(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSaving(true);

    try {
      await api.put(`/orders/update-order/${order.id}`, {
        id: order.id,
        customer,
        phone,
        address: {
          direction,
          number,
          addressComplement,
          addressNotes,
          neighbourhood,
        },
        notes,
        paymentMethod,
        moneyChange: moneyChange * 100,
        shippingCost,
        subtotal,
        total,
        items,
        discountAmount,
        discount: order?.discount?.id,
      });
    } catch (error: any) {
      if (error.response.data.error.details) {
        setDialog({
          open: true,
          title: "Não foi possivel alterar o pedido",
          message: error.response.data.error.details.message,
          callbackConfirm: null,
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (event: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const onRemove = (id, action) => {
    const newItems = items.map((item) => {
      if (item.id === id) {
        item.isRemoved = action;
      }
      return item;
    });

    setItems(newItems);
    calculateValues();
  };

  const onChangeQuantity = (id, quantity) => {
    if (quantity < 1) return;

    const newItems = items.map((item) => {
      if (item.id === id) {
        item.quantity = quantity;
        item.totalPrice = item.price * quantity;
      }
      return item;
    });

    setItems(newItems);
    calculateValues();
  };

  const calculateValues = (shippingCost_: any = null) => {
    const subtotal = items.reduce((acc, item) => {
      if (!item.isRemoved) {
        acc += item.quantity * item.price;
      }
      return acc;
    }, 0);

    const shipping = (shippingCost_ !== null ? shippingCost_ : shippingCost) * 100;

    let discountAmount = 0;

    if (order.discount) {
      const discount = order.discount;

      if (discount.type === "percent") {
        discountAmount = Math.round(discount.amount * (subtotal / 10000));

        if (discountAmount > discount.maxDiscountAllowed) {
          discountAmount = discount.maxDiscountAllowed;
        }
      } else if (discount.type === "value") {
        discountAmount = discount.amount;
        if (subtotal < discountAmount) {
          discountAmount = subtotal;
        }
      }

      setDiscountAmount(discountAmount);
    }

    const total = subtotal - discountAmount + (shipping || 0);

    setSubtotal(subtotal);
    setTotal(total);
  };

  const isSaveButtonDisabled = () => {
    const findPaymentMethod = paymentMethods.find((method) => method.id === paymentMethod);

    let isMoneyChangeValid = true;

    if (findPaymentMethod?.isMoney) {
      if (moneyChange === 0) isMoneyChangeValid = true;
      else if (moneyChange * 100 < total) {
        isMoneyChangeValid = false;
      }
    }

    return isSaving || isNaN(shippingCost) || subtotal <= 0 || disabled || !isMoneyChangeValid;
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" className="OrderFormDialog" open={show}>
      <DialogTitle id="simple-dialog-title">
        Pedido de: {order.customer} ({order.code})
      </DialogTitle>

      <form>
        <Card variant="outlined" className="AddTaskCard">
          <Box className="OrderFormTabs">
            <Tabs value={currentTab} onChange={handleChange} indicatorColor="primary">
              <Tab label="Informações básicas" />
              <Tab label="Produtos & Entrega" />
              {order?.discount && <Tab label="Desconto" />}
            </Tabs>
          </Box>
          <CardContent className="AddTaskCardContent">
            <Box hidden={currentTab !== 0}>
              <TextField
                className="AddTaskTextField"
                required
                label="Cliente"
                defaultValue={customer}
                disabled={disabled}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomer(event.target.value)}
              />

              <TextField
                className="AddTaskTextField"
                required
                label="Telefone"
                defaultValue={phone}
                disabled={disabled}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)}
              />

              <div className="space" />

              <TextField
                className="AddTaskTextField"
                required
                label="Rua"
                defaultValue={direction}
                disabled={disabled}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setDirection(event.target.value)}
              />

              <TextField
                className="AddTaskTextField"
                required
                label="Número"
                defaultValue={number}
                disabled={disabled}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setNumber(event.target.value)}
              />

              <TextField
                className="AddTaskTextField"
                label="Complemento"
                defaultValue={addressComplement}
                disabled={disabled}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setAddressComplement(event.target.value)}
              />

              <TextField
                className="AddTaskTextField"
                label="Ponto de referência"
                defaultValue={addressNotes}
                disabled={disabled}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setAddressNotes(event.target.value)}
              />

              <TextField
                className="AddTaskTextField"
                required
                label="Bairro"
                defaultValue={neighbourhood}
                disabled
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setNeighbourhood(event.target.value)}
              />

              <div className="space" />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Forma de pagamento</InputLabel>
                <Select
                  className="AddTaskSelectField"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentMethod}
                  disabled={disabled}
                  label="Forma de pagamento"
                  variant="outlined"
                  onChange={(event: any) => {
                    setPaymentMethod(event.target.value);
                  }}
                >
                  {paymentMethods.map((paymentMethod) => (
                    <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
                      {paymentMethod.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="space" />

              <NumericFormat
                defaultValue={moneyChange}
                disabled={disabled}
                customInput={TextField}
                className="AddTaskTextField"
                label="Troco"
                prefix="R$ "
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                type="text"
                variant="outlined"
                onValueChange={({ value }) => {
                  setMoneyChange(parseFloat(value));
                }}
              />

              <div className="space" />

              <TextField
                className="AddTaskTextField"
                label="Observações"
                defaultValue={notes}
                disabled={disabled}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setNotes(event.target.value)}
              />
            </Box>
            <Box hidden={currentTab !== 1}>
              <NumericFormat
                defaultValue={shippingCost}
                disabled={disabled}
                customInput={TextField}
                className="AddTaskTextField"
                label="Taxa de entrega"
                prefix="R$ "
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale
                type="text"
                required
                variant="outlined"
                onValueChange={({ value }) => {
                  setShippingCost(parseFloat(value));
                  calculateValues(parseFloat(value));
                }}
              />

              <Autocomplete
                disablePortal
                ref={autoC}
                disabled={disabled}
                options={products.filter((product) => !items.find((item) => item.product.id === product.id))}
                getOptionLabel={(option: any) => {
                  const formattedPrice = option.formattedSalePrice
                    ? `* ${option.formattedSalePrice} *`
                    : option.formattedPrice;

                  return `${option.referenceCode ? "(" + option.referenceCode + ")" : ""} ${
                    option.name
                  } ${formattedPrice}`;
                }}
                filterOptions={(options, state) => {
                  const term = state.inputValue.toLowerCase();
                  const termWords = term.split(" ");
                  return options.filter((item) => {
                    return termWords.every((word) =>
                      ignoreAccentsAndCases(item.name).includes(ignoreAccentsAndCases(word))
                    );
                  });
                }}
                onChange={(event: any, newValue: any) => {
                  if (newValue) {
                    if (newValue.salePrice) {
                      newValue.originalPrice = newValue.price;
                      newValue.price = newValue.salePrice;
                    }

                    items.unshift({
                      id: newValue.id,
                      price: newValue.price,
                      quantity: 1,
                      totalPrice: newValue.price,
                      originalPrice: newValue.salePrice ? newValue.originalPrice : undefined,
                      originalTotalPrice: newValue.salePrice ? newValue.price : undefined,
                      saleId: newValue.salePrice ? newValue.saleId : undefined,
                      isRemoved: false,
                      isNew: true,
                      product: {
                        name: newValue.name,
                        id: newValue.id,
                        image: newValue.image,
                      },
                    });

                    setTimeout(() => {
                      if (autoC.current) {
                        // @ts-ignore
                        autoC.current.querySelector(".MuiAutocomplete-clearIndicator").click();
                      }
                    }, 100);

                    setItems([...items]);
                    calculateValues();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={disabled}
                    variant="outlined"
                    className="AddTaskTextField"
                    style={{ paddingBottom: 0 }}
                    label="Adicionar Produto"
                  />
                )}
              />
              <Box style={{ marginBottom: 20 }} />

              {items.map((item, index) => (
                <ItemDetail
                  key={index}
                  {...item}
                  isEditing
                  disabled={disabled}
                  className="OrderFormItem"
                  onRemove={onRemove}
                  onChangeQuantity={onChangeQuantity}
                />
              ))}
            </Box>
            <Box hidden={currentTab !== 2}>
              <DiscountLine label="Código" value={order?.discount?.code} />

              <DiscountLine label="Tipo" value={order?.discount?.type === "percent" ? "Percentual" : "Valor"} />

              <DiscountLine
                label="Regra"
                value={
                  order?.discount?.type === "percent" ? (
                    <>
                      {order?.discount?.formattedAmount}% (max: {order?.discount?.formattedMaxDiscountAllowed})
                    </>
                  ) : (
                    <>
                      {order?.discount?.formattedAmount} (min:
                      {order?.discount?.formattedMinOrderAmount})
                    </>
                  )
                }
              />
            </Box>
          </CardContent>
          <CardActions className="AddTaskCardAction">
            <Box display={"flex"} flexDirection="column" flex={1}>
              <Typography variant="body1">Total: {formatPrice(total)}</Typography>
              <DiscountInconsistency discount={order.discount} subtotal={subtotal} />
              <MoneyChangeInconsistency paymentMethod={paymentMethod} moneyChange={moneyChange} total={total} />
            </Box>

            <Button onClick={() => onClose()} disabled={isSaving} variant="text" style={{ marginRight: 12 }}>
              Fechar
            </Button>
            <Button onClick={handleSubmit} disabled={isSaveButtonDisabled()} variant="contained" color="primary">
              Salvar
            </Button>
            <CircularProgress size={isSaving ? 20 : 0} />
          </CardActions>
        </Card>
      </form>
    </Dialog>
  );
};

const DiscountLine = ({ label, value }) => {
  return (
    <Box className="OrderDetailItem">
      <Typography color="textSecondary" variant="body2">
        {label}
      </Typography>

      <Typography color="textSecondary" variant="body2">
        {value || "-"}
      </Typography>
    </Box>
  );
};

const DiscountInconsistency = ({ discount, subtotal }) => {
  if (!discount || discount?.type === "percent") return null;

  if (subtotal >= discount?.minOrderAmount) return null;

  return (
    <Typography
      variant="body2"
      style={{
        color: "#f50000",
      }}
    >
      Valor mínimo {formatPrice(discount.minOrderAmount)}, subtotal {formatPrice(subtotal)}
    </Typography>
  );
};

const MoneyChangeInconsistency = ({ moneyChange, total, paymentMethod }) => {
  const paymentMethods = useStore((state) => state.paymentMethods);

  const findPaymentMethod = paymentMethods.find((method) => method.id === paymentMethod);

  if (!findPaymentMethod?.isMoney || moneyChange === 0) return null;

  if (moneyChange * 100 >= total) return null;

  return (
    <Typography
      variant="body2"
      style={{
        color: "#f50000",
      }}
    >
      Troco deve ser maior que o total (ou igual a zero)
    </Typography>
  );
};
