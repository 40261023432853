import { useEffect, useState } from "react";

const oneMinute = 1000 * 60;

export const Timer: React.FC<{
  createdAt: any;
}> = ({ createdAt }) => {
  const [timePast, setTimePast] = useState(
    Math.floor((new Date().getTime() - new Date(createdAt).getTime()) / 1000 / 60)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimePast(Math.floor((new Date().getTime() - new Date(createdAt).getTime()) / 1000 / 60));
    }, oneMinute);
    return () => {
      clearInterval(interval);
      console.log("clear interval");
    };
  }, [createdAt]);

  return (
    <div
      className="timer"
      style={{
        borderColor: timePast > 20 ? "#e54141" : timePast > 10 ? "#ffb205" : "#4caf50",
      }}
    >
      {timePast}
    </div>
  );
};
