import React from "react";
import { CssBaseline } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";

import "./App.css";
import Router from "./Router";

function App() {
  return (
    <div className="App">
      <StylesProvider injectFirst>
        <CssBaseline />
        <Router />
      </StylesProvider>
    </div>
  );
}

export default App;
