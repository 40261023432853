import React from "react";
import { Button, Chip, useMediaQuery, useTheme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import AppToolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import "./Toolbar.css";
import { useStore } from "../../store";
import { useHistory } from "react-router-dom";
import SearchOrder from "../Search/SearchOrder";

import packageJson from "../../../package.json";

export const title = "Controle de pedidos";

export const Toolbar = ({ companyName }) => {
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const onlineIndicator = useStore((state) => state.onlineIndicator);

  const logout = useStore((state) => state.logout);

  const isOpen = useStore((state) => state.isOpen);

  const style = isOpen ? { backgroundColor: "#4caf50", color: "#fff" } : { backgroundColor: "#f44336", color: "#fff" };
  const label = isOpen ? "Loja aberta" : "Loja fechada";

  return (
    <AppBar position="sticky" className="AppBar" color="inherit">
      <AppToolbar>
        <div className="HeaderContainer">
          <div className="HeaderLeftContainer">
            <div
              className={["status-indicator", onlineIndicator ? "indicator-green" : "indicator-red"].join(" ")}
            ></div>
            <Typography variant={isSmallScreen ? "subtitle1" : "h5"} color="inherit" noWrap>
              {title} - {companyName}
            </Typography>
            <Chip label={label} style={style} />

            <SearchOrder />
          </div>
          <div>
            v{packageJson.version}
            <Button
              title="Sair"
              startIcon={<ExitToAppIcon />}
              color="primary"
              onClick={() => {
                logout();
                history.push("/login");
              }}
            >
              Sair
            </Button>
          </div>
        </div>
      </AppToolbar>
    </AppBar>
  );
};
