import { Button, Card, CardActions, CardContent, CardHeader, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api, setBearerToken } from "../../config/api";
import { useStore } from "../../store";
import "./Login.css";

export const Login = () => {
  const history = useHistory();
  // const [identifier, setIdentifier] = useState("cezar.arndt@gmail.com");
  // const [password, setPassword] = useState("Cezar230303;");

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const setUser = useStore((state) => state.setUser);
  const jwt = useStore((state) => state.jwt);

  useEffect(() => {
    if (jwt) {
      setBearerToken(jwt);
      history.push("/");
    }
  }, [jwt, history]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const { data } = await api.post("/auth/local", {
        identifier,
        password,
      });

      setUser(data);
    } catch (error) {
      //@ts-ignore
      if (error.response.status === 400) {
        //@ts-ignore
        setErrorMessage(error.response.data.error.message);
      } else {
        setErrorMessage("Ocorreu um erro inesperado");
      }
    }

    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card variant="outlined" className="CreateBoardCard">
        <CardHeader
          className="CreateBoardCardHeader"
          title="Controle de pedidos"
          titleTypographyProps={{ variant: "h4" }}
        />
        <CardContent className="CreateBoardCardContent">
          <TextField
            className="CreateBoardTextField"
            required
            id="filled-required"
            label="E-mail"
            defaultValue={identifier}
            variant="outlined"
            autoComplete="off"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setIdentifier(event.target.value)}
          />
          <TextField
            className="CreateBoardTextField"
            required
            id="filled-required"
            label="Senha"
            defaultValue={password}
            variant="outlined"
            type="password"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          />
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </CardContent>

        <CardActions className="CreateBoardCardAction">
          <Button type="submit" variant="contained" color="primary" className="CreateBoardButton">
            Entrar
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
