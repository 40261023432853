import React from "react";

//  const { initializeDrag } = useDragableContent();

export const useDragableContent = () => {
  const isMouseDown = React.useRef(false);
  const isDragging = React.useRef(false);
  const dragableContent = React.useRef(null);
  const startMovePositionX = React.useRef(0);

  const initializeDrag = (ref: any) => {
    //@ts-ignore
    dragableContent.current = document.querySelector(".react-kanban-board");

    //@ts-ignore
    ref.addEventListener("mousedown", onMouseDown);
    //@ts-ignore
    ref.addEventListener("mousemove", onMouseMove);
    //@ts-ignore
    ref.addEventListener("mouseup", onMouseUp);

    //@ts-ignore
    ref.addEventListener("mouseleave", onMouseUp);
  };

  const onMouseDown = (e) => {
    if (e.target.closest(".TaskCard")) {
      return;
    }

    e.preventDefault();
    isMouseDown.current = true;
    startMovePositionX.current = e.clientX;
  };

  const onMouseMove = (e) => {
    e.preventDefault();
    if (isMouseDown.current && dragableContent.current !== null) {
      isDragging.current = true;
      const moveX = e.clientX - startMovePositionX.current;
      //@ts-ignore
      dragableContent.current.scrollLeft = dragableContent.current.scrollLeft - moveX;
      startMovePositionX.current = e.clientX;
    }
  };

  const onMouseUp = (e) => {
    e.preventDefault();
    isMouseDown.current = false;
    isDragging.current = false;
  };

  // useEffect(() => {
  //   return () => {
  //     if (dragableContent.current) {
  //       //@ts-ignore
  //       ref.removeEventListener("mousedown", onMouseDown);
  //       //@ts-ignore
  //       ref.removeEventListener("mousemove", onMouseMove);
  //       //@ts-ignore
  //       ref.removeEventListener("mouseup", onMouseUp);
  //       //@ts-ignore
  //       ref.removeEventListener("mouseleave", onMouseUp);
  //     }
  //   };
  // }, []);

  return { initializeDrag };
};
