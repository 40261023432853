import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { BoardsPage } from "./pages/BoardPage/BoardPage";
import HomePage from "./pages/HomePage/HomePage";

const isAuthenticated = () => {
  const jwt = localStorage.getItem("jwt") || null;
  if (jwt) {
    return true;
  }

  return false;
};

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <BrowserRouter>
    {/* <Toolbar /> */}
    <Switch>
      <PrivateRoute path="/" exact component={BoardsPage} />
      <Route path="/login" exact component={HomePage} />
      <Route path="*" exact component={HomePage} />
    </Switch>
  </BrowserRouter>
);
