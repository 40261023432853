import React from "react";
import { Grid, Typography, useMediaQuery, useTheme, Box } from "@material-ui/core";
import { Footer } from "../../components/Footer/Footer";
import LandingImage from "./../../images/background.jpg";
import "./HomePage.css";
import { Login } from "../../components/Login/Login";

export const HomePage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box display={"flex"} flexDirection="column" overflow={"hidden"}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
        style={{
          minHeight: "calc(100vh - 75px)",
        }}
      >
        <Grid container item sm={12} lg={9} justify="center" alignItems="center" spacing={3}>
          <Grid item sm={12} lg={6}>
            <div className="HomePageContainer">
              <Typography variant="h5"></Typography>
              <img
                alt="Pedidos"
                style={{ height: "400px", width: "500px", transform: isSmallScreen ? "scale(0.5)" : "none" }}
                src={LandingImage}
              ></img>
              {/* <Typography variant="subtitle1">
                Free / Open source React MaterialUI Template - Task Board App. Template comes with most essential things
                of Typescript, Lint, prettier, React Router, Material-UI and Cool Landing Page to bootstrap your web
                app. Just clone the Repo and start building your app.
              </Typography> */}
            </div>
          </Grid>
          <Grid item sm={12} lg={6}>
            <div className="HomePageContainer">
              <Login />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default HomePage;
