export const dateFormatted = (date, fullDate = false) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();
  const hour = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");

  //if date is today show only time
  if (!fullDate && new Date().toDateString() === dateObject.toDateString()) {
    return `${hour}:${minutes}`;
  }

  return `${day}/${month}/${year} ${hour}:${minutes}`;
};
