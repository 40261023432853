import axios from "axios";
import { useStore } from "../store";
export const URL_SERVER = process.env.REACT_APP_URL_SERVER || "";

const api = axios.create({
  baseURL: `${URL_SERVER}/api`,
});

api.interceptors.request.use((config) => {
  return config;
});

export const setBearerToken = (token) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

//remove bearerToken
export const removeBearerToken = () => {
  api.defaults.headers.common["Authorization"] = null;
};

let requestQueue = [];
let isRefreshing = false;

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Check if the request received a 401 Unauthorized response
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;

        // Make a request to refresh the token
        const refreshToken = localStorage.getItem("refreshToken");
        try {
          const response = await axios.post(`${URL_SERVER}/api/token/refresh`, {
            refreshToken,
          });

          // Update the JWT token and refresh token in local storage
          localStorage.setItem("jwt", response.data.jwt);
          localStorage.setItem("refreshToken", response.data.refreshToken);

          // Set the new token in the request's authorization header
          api.defaults.headers.common.Authorization = `Bearer ${response.data.jwt}`;

          // Retry the original request
          originalRequest.headers = {
            ...originalRequest.headers,
          };

          originalRequest.headers.Authorization = `Bearer ${response.data.jwt}`;

          originalRequest._retry = true;

          requestQueue.forEach((req) => {
            req.headers.Authorization = `Bearer ${response.data.jwt}`;
            api(req);
          });

          return api(originalRequest);
        } catch (refreshError) {
          console.error(refreshError);
          const logout = useStore.getState().logout;
          logout();
          window.location.href = "/login";
          return Promise.reject(refreshError);
        } finally {
          requestQueue = [];
          isRefreshing = false;
        }
      } else {
        requestQueue.push(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export { api };
