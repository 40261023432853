import { Divider, Chip, Typography, Box } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useMemo } from "react";
import { dateFormatted } from "../../../config/date";
import { useStore } from "../../../store";
import "./OrderCard.css";
import { Timer } from "./Timer";

export interface TaskCardProps {
  task: any;
  boardId: string;
}

export const OrderCard: React.FC<TaskCardProps> = ({ boardId, task }) => {
  const setCurrentOrder = useStore((state) => state.setCurrentOrder);
  const currentOrder = useStore((state) => state.currentOrder);

  const isFinishedOrCanceled = useMemo(() => {
    const statusFinishedOrCanceled = useStore
      .getState()
      .board.columns.filter((c) => [-1, 1000].includes(c.order))
      .map((c) => c.id);
    return statusFinishedOrCanceled.includes(task.status);
  }, [task.status]);

  if (!task) {
    return null;
  }

  const itensLength = task.items.reduce((acc: number, item: any) => {
    return acc + item.quantity;
  }, 0);

  return (
    <div className={["TaskCard", currentOrder && currentOrder.id === task.id ? "selected" : ""].join(" ")}>
      <div className="TaskCardHeader">
        <div>
          {task.code}
          <br />
          <Typography variant="caption">{dateFormatted(task.createdAt)}</Typography>
        </div>
        <button
          className={["TaskDeleteButton", task.opened ? "opened" : ""].join(" ")}
          title="View Order"
          onClick={() => {
            setCurrentOrder(task);
          }}
        >
          <Visibility></Visibility>
        </button>
      </div>
      <Divider variant="middle"></Divider>
      <div className="TaskCardContent" title={task.customer}>
        <div>
          <span>Bairro: {task.neighbourhood?.name}</span>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Chip label={`${itensLength} ${itensLength === 1 ? "item" : "itens"}`} />
            {!isFinishedOrCanceled && <Timer createdAt={task.createdAt} />}
          </Box>
          <div>
            {task.courier && <Chip color="primary" label={`${task.courier.name}`} />}
            {task.deliveryTime && <Chip color="secondary" label={`${task.deliveryTime[0]}~${task.deliveryTime[1]}`} />}
          </div>
        </div>
      </div>
      <div className="TaskCardFooter">
        <div>{task.paymentMethod.name}</div>
        <div className="price">
          <div className={task?.originalTotal ? "line-through" : ""}>{task.originalTotalFormatted}</div>
          <div>{task.totalFormatted}</div>
        </div>
      </div>
    </div>
  );
};
