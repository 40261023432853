import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade } from "@material-ui/core";
import React, { useEffect } from "react";
import Pusher from "pusher-js";
import { decompress } from "compress-json";

import Board, { moveCard } from "@asseinfo/react-kanban";
import { OrderCard } from "../../Order/OrderCard/OrderCard";
import "./BoardArea.css";

import "@asseinfo/react-kanban/dist/styles.css";
import { useDragableContent } from "../../useDragableContent";
import { fnIntervalMissUpdates, playBeep, useStore } from "../../../store";
import { api } from "../../../config/api";

import BoardHeader from "./BoardHeader";

let config = {};

if (process.env.REACT_APP_SOKETI_TLS === "true") {
  config = {
    wsHost: process.env.REACT_APP_SOKETI_HOST,
    forceTLS: true,
    disableStats: true,
    cluster: "",
    enabledTransports: ["ws", "wss"],
  };
} else {
  config = {
    wsHost: process.env.REACT_APP_SOKETI_HOST,
    wsPort: process.env.REACT_APP_SOKETI_PORT,
    forceTLS: false,
    disableStats: true,
    cluster: "",
    enabledTransports: ["ws", "wss"],
  };
}

//@ts-ignore
export const pusher = new Pusher(process.env.REACT_APP_SOKETI_APP_KEY || "", config);

pusher.connection.bind("connected", () => {
  useStore.getState().setOnlineIndicator(true);
});

interface BoardAreaProps {
  board: Board;
}

let intervalMissUpdates: any = null;
const intervarlTimer = 60 * 1000;

let intervalWaitingOrders: any = null;
const intervarlWaitingOrders = 0.5 * 60 * 1000;

export const BoardArea: React.FC<BoardAreaProps> = ({ board: _board }) => {
  const draggableContentRef = React.useRef(null);

  const board = useStore((state) => state.board);
  const setOrders = useStore((state) => state.setOrders);
  const updateBoard = useStore((state) => state.updateBoard);
  const setPaymentMethods = useStore((state) => state.setPaymentMethods);
  const setCouriers = useStore((state) => state.setCouriers);
  const setSettings = useStore((state) => state.setSettings);
  const settings = useStore((state) => state.settings);
  const setIsSearchingByHours = useStore((state) => state.setIsSearchingByHours);

  const hasInteracted = useStore((state) => state.hasInteracted);
  const setHasInteracted = useStore((state) => state.setHasInteracted);
  const setOnlineIndicator = useStore((state) => state.setOnlineIndicator);

  const updateOrder = useStore((state) => state.updateOrder);
  const addOrder = useStore((state) => state.addOrder);
  const setIsOpen = useStore((state) => state.setIsOpen);

  const dialog = useStore((state) => state.dialog);
  const setDialog = useStore((state) => state.setDialog);
  const checkMissUpdates = useStore((state) => state.checkMissUpdates);
  const setTimeStamp = useStore((state) => state.setTimeStamp);

  const historyHours = useStore((state) => state.historyHours);

  const onlineIndicator = useStore((state) => state.onlineIndicator);

  const refOrderId = React.useRef(null);

  const { initializeDrag } = useDragableContent();

  useEffect(() => {
    setIsSearchingByHours(true);
    api
      .get(`/orders/all?hours=${historyHours}`)
      .then((response) => {
        setOrders(response.data);

        api.get(`/orders/check-miss-updates?timestamp=`).then((data) => {
          checkMissUpdates(data);
        });
      })
      .finally(() => {
        setIsSearchingByHours(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyHours]);

  useEffect(() => {
    api.get("/payment-methods?filters[enabled][$eq]=true").then((response) => {
      setPaymentMethods(response.data);
    });

    api.get("/company-settings").then((response) => {
      setSettings(response.data);
    });

    api.get("/orders/couriers").then((response) => {
      setCouriers(response.data);
    });

    if (draggableContentRef.current !== null) {
      initializeDrag(draggableContentRef.current);
    }

    if (!hasInteracted) {
      setDialog({
        open: true,
        title: `Atenção`,
        message: `É necessário interagir com o sistema para que as notificações funcionem corretamente`,
        extraAction: () => setHasInteracted(true),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupSocketListeners = () => {
    if (settings.uid !== undefined) {
      pusher.subscribe(settings.uid);
      pusher.subscribe(`public-${settings.uid}`);

      pusher.bind("open", ({ isOpen }) => {
        setIsOpen(isOpen);
      });

      pusher.bind("update-status", (data) => {
        if (data.id === refOrderId.current) {
          setDialog({ ...dialog, open: false, callbackConfirm: null });
        }
        setTimeStamp(data.timestamp);
        updateOrder(data.id, { status: data.status });
      });

      pusher.bind("update-opened", (data) => {
        setTimeStamp(data.timestamp);
        updateOrder(data.id, { opened: true });
      });

      pusher.bind("update-accepted", (data) => {
        setTimeStamp(data.timestamp);
        updateOrder(data.id, { accepted: true, status: data.status });
      });

      pusher.bind("update-order", (data) => {
        const replace = true;
        const order = decompress(data.order);
        setTimeStamp(data.timestamp);
        updateOrder(order.id, order, replace);
        document.dispatchEvent(new CustomEvent("close-modal"));
      });

      pusher.bind("update-deliveryTime", (data) => {
        const deliveryTime =
          data?.deliveryTime?.[0] === null && data?.deliveryTime?.[1] === null ? null : data?.deliveryTime;
        setTimeStamp(data.timestamp);
        updateOrder(data.id, { courier: data.courier, deliveryTime });
      });

      pusher.bind("assign-to-courier", (data) => {
        setTimeStamp(data.timestamp);
        updateOrder(data.id, { courier: data.courier, status: data.status });
      });

      pusher.bind(`new-order`, (data) => {
        api.get(`/orders/${data.id}`).then(({ data }) => {
          setTimeStamp(data.timestamp);
          addOrder(data);
        });
      });

      intervalMissUpdates = setInterval(fnIntervalMissUpdates, intervarlTimer);
      intervalWaitingOrders = setInterval(playBeep, intervarlWaitingOrders);
    }

    pusher.connection.bind("connected", () => {
      if (useStore.getState()?.lastTimestamp !== null) {
        fnIntervalMissUpdates();
      }

      setOnlineIndicator(true);
    });

    pusher.connection.bind("error", (err) => {
      setOnlineIndicator(false);
    });
    pusher.connection.bind("unavailable", (err) => {
      setOnlineIndicator(false);
    });
    return () => {
      pusher.unbind_all();

      clearInterval(intervalMissUpdates);
      clearInterval(intervalWaitingOrders);
    };
  };

  useEffect(() => {
    const cleanup = setupSocketListeners();

    return () => {
      cleanup();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(settings), onlineIndicator]);

  const onCardDragEnd = async (card, source, destination) => {
    const findColumn = useStore.getState().getStatus(destination.toColumnId);

    const move = async () => {
      updateBoard(moveCard(useStore.getState().board, source, destination));
    };

    const revert = async () => {
      const invertSource = {
        fromColumnId: destination.toColumnId,
        fromPosition: destination.toPosition,
      };

      const invertDestination = {
        toColumnId: source.fromColumnId,
        toPosition: source.fromPosition,
      };

      updateBoard(moveCard(useStore.getState().board, invertSource, invertDestination));
    };

    if (findColumn && [1000, -1].indexOf(findColumn.order) > -1) {
      if (source.fromColumnId === destination.toColumnId) {
        move();
        return;
      }

      refOrderId.current = card.id;
      setDialog({
        open: true,
        title: `Mover o pedido para ${findColumn.title}?`,
        message: `Tem certeza que deseja alterar o status do pedido para ${findColumn.title}?`,
        callbackConfirm: async () => {
          setDialog({ ...dialog, open: false, callbackConfirm: null });

          move();

          if (source.fromColumnId === destination.toColumnId) {
            return;
          }

          api
            .put(`/orders/update-status/${card.id}`, {
              status: destination.toColumnId,
            })
            .catch((error) => {
              if (error.response.data.error.details) {
                setDialog({
                  open: true,
                  title: "Não foi possivel alerar o status do pedido",
                  message: error.response.data.error.details.message,
                  callbackConfirm: null,
                });

                revert();
              }
            })
            .finally(() => {
              refOrderId.current = null;
            });
        },
      });
      return;
    } else {
      setDialog({ ...dialog, open: false, callbackConfirm: null });

      move();

      if (source.fromColumnId === destination.toColumnId) {
        return;
      }

      api
        .put(`/orders/update-status/${card.id}`, {
          status: destination.toColumnId,
        })
        .catch((error) => {
          if (error.response.data.error.details) {
            setDialog({
              open: true,
              title: "Não foi possivel alerar o status do pedido",
              message: error.response.data.error.details.message,
              callbackConfirm: null,
            });

            revert();
          }
        })
        .finally(() => {
          refOrderId.current = null;
        });
    }
  };

  const renderCard = ({ content }, { removeCard, dragging }) => {
    return <OrderCard boardId={_board.id.toString()} key={content.id} task={content}></OrderCard>;
  };
  return (
    <>
      <Fade in={true} timeout={2000}>
        <div className="BoardContent">
          <BoardHeader />
          <div className="BoardContent" ref={draggableContentRef}>
            <Board disableColumnDrag={true} renderCard={renderCard} onCardDragEnd={onCardDragEnd}>
              {board}
            </Board>
          </div>
        </div>
      </Fade>

      <Dialog open={dialog.open} onClose={() => {}}>
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            padding: 20,
          }}
        >
          {dialog.callbackConfirm ? (
            <>
              <Button
                onClick={() => {
                  refOrderId.current = null;
                  setDialog({
                    ...dialog,
                    open: false,
                    callbackConfirm: null,
                  });
                }}
              >
                Nao Alterar
              </Button>
              <Button variant="contained" color="primary" onClick={dialog.callbackConfirm}>
                Confirmar mudança
              </Button>
            </>
          ) : dialog.extraAction ? (
            <Button
              onClick={() => {
                dialog.extraAction?.();
                setDialog({
                  ...dialog,
                  open: false,
                  callbackConfirm: null,
                  extraAction: null,
                });
              }}
            >
              Entendi
            </Button>
          ) : (
            <Button
              onClick={() => {
                refOrderId.current = null;
                setDialog({
                  ...dialog,
                  open: false,
                  callbackConfirm: null,
                });
              }}
            >
              Entendi
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BoardArea;

// const getTasksByStatus = (tasks: any[], status: string) => {
//   const filteredTasks = tasks
//     .filter((task) => task.status === status)
//     .map((task) => {
//       return {
//         id: task.id,
//         content: {
//           ...task,
//         },
//       };
//     });
//   return filteredTasks;
// };
