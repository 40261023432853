import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Button, TextField, CircularProgress, Slider, Popover } from "@material-ui/core";
import { DeleteForeverOutlined, RemoveCircleOutline, AddCircleOutline, Restore } from "@material-ui/icons";

import { useStore } from "../../../store";

import { Close } from "@material-ui/icons";

import "./OrderDetail.css";

import { OrderForm } from "../../Order/OrderForm/OrderForm";
import { formatPrice } from "../../../config/price";
import { Autocomplete } from "@material-ui/lab";
import { api } from "../../../config/api";

import placeHolderImage from "../../../images/placeholder-no-photo.png";
import { dateFormatted } from "../../../config/date";
import { printTemplate } from "../../../config/print";

import { WhatsApp } from "@material-ui/icons";

export const OrderDetail = () => {
  const [showOrderForm, setShowOrderForm] = React.useState(false);

  const currentOrder = useStore((state) => state.currentOrder);
  const setCurrentOrder = useStore((state) => state.setCurrentOrder);

  const className = currentOrder ? ["OrderDetail", "isOpen"] : ["OrderDetail"];

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [acceptingOrder, setAcceptingOrder] = useState(false);
  const setDialog = useStore((state) => state.setDialog);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const acceptOrder = async () => {
    setAcceptingOrder(true);
    try {
      await api.put(`/orders/accept/${currentOrder?.id}`, {});
      handleClose();
      handlePrint();
    } catch (error: any) {
      if (error.response.data.error.details) {
        setDialog({
          open: true,
          title: "Não foi possivel aceitar o pedido",
          message: error.response.data.error.details.message,
          callbackConfirm: null,
        });
      }
    } finally {
      setAcceptingOrder(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let closedOrCanceled = true;

  if (currentOrder) {
    const currentStatusOrder = useStore.getState().getStatus(currentOrder?.status || -1);
    closedOrCanceled = [-1, 1000].includes(currentStatusOrder.order);
  }

  const handlePrint = () => {
    const ticket = {
      header: useStore.getState().settings.ticketHeader || "",
      footer: useStore.getState().settings.ticketFooter || "",
      copies: useStore.getState().settings.ticketCopies,
    };

    let newWindow = window.open();
    newWindow?.document.write(printTemplate(ticket, [currentOrder]));
    newWindow?.document.close();
    newWindow?.focus();
    newWindow?.print();
    newWindow?.close();
  };

  return (
    <Box className={className.join(" ")}>
      <Box className="OrderDetailHeader">
        <Typography color="primary" variant="body1">
          {currentOrder?.code}
        </Typography>
        <Typography color="textPrimary" variant="body1">
          {currentOrder?.statusName}
        </Typography>

        <button
          className="TaskDeleteButton"
          title="View Order"
          onClick={() => {
            setCurrentOrder(undefined);
          }}
        >
          <Close></Close>
        </button>
      </Box>

      <Button variant="outlined" color="default" size="small" style={{ margin: "10px" }} onClick={handlePrint}>
        Imprimir
      </Button>

      <Box className="OrderDetailContent">
        <Detail title="Criado em" value={dateFormatted(currentOrder?.createdAt)} />
        <Detail title="Código" value={currentOrder?.code} />
        <Detail title="Cliente" value={currentOrder?.customer} />
        <TelDetail title="Telefone" value={currentOrder?.phone} />
        <div className="space" />
        <Detail
          title="Rua"
          value={`${currentOrder?.direction}, ${currentOrder?.number} ${currentOrder?.addressComplement}`}
        />
        <Detail title="Ponto de referencia" value={currentOrder?.addressNotes} />
        <Detail title="Bairro" value={currentOrder?.neighbourhood?.name} />
        <div className="space" />
        <div className="space" />
        <Detail title="Subtotal" value={currentOrder?.subtotalFormatted} />
        <Detail title="Taxa de entrega" value={currentOrder?.shippingCostFormatted} />
        <Detail title="Total" className="line-through" value={currentOrder?.originalTotalFormatted} />
        <Detail title="Total" value={currentOrder?.totalFormatted} />

        {currentOrder?.discount && <Detail title="Cupom" value={currentOrder?.discount?.code} />}

        <Detail
          title="Pagamento em"
          value={`${currentOrder?.paymentMethod.name} ${
            currentOrder?.moneyChange > 0 ? "(Troco para " + currentOrder?.moneyChangeFormatted + ")" : ""
          }`}
        />
        <Detail title="Observações" value={currentOrder?.notes} />
        <div className="space" />
        <div className="space" />
        <SetDeliverySettings
          orderId={currentOrder?.id}
          currentCourier={currentOrder?.courier}
          deliveryTime={currentOrder?.deliveryTime}
          disabled={closedOrCanceled}
        />
        <div className="space" />
        <div className="space" />
        {currentOrder?.items.map((item) => (
          <ItemDetail key={item.id} {...item} />
        ))}
      </Box>
      <Box className="OrderDetailFooter" style={{ gap: "10px" }}>
        {currentOrder?.accepted ? (
          <Button variant="text" color="secondary" disabled>
            Pedido aceito
          </Button>
        ) : (
          <>
            <Button variant="outlined" color="secondary" onClick={handleClick} disabled={closedOrCanceled}>
              Aceitar pedido
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={() => {}}
              style={{ width: "400px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box
                style={{
                  padding: 20,
                }}
              >
                <Typography variant="button">Aceitar pedido ?</Typography>
                <Typography variant="body1">
                  Ao aceitar esse pedido ele será alterado para o próximo status e o cliente é notificado
                </Typography>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <Button variant="text" color="default" disabled={acceptingOrder} onClick={handleClose}>
                    Decidir depois
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={acceptingOrder || closedOrCanceled}
                    onClick={acceptOrder}
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Aceitar
                  </Button>
                  <CircularProgress
                    size={acceptingOrder ? 20 : 0}
                    style={{
                      marginLeft: acceptingOrder ? "10px" : "0",
                    }}
                  />
                </Box>
              </Box>
            </Popover>
          </>
        )}

        {closedOrCanceled ? null : (
          <Button variant="outlined" color="primary" onClick={() => setShowOrderForm(true)}>
            Editar pedido
          </Button>
        )}
      </Box>

      {showOrderForm && (
        <OrderForm
          show={true}
          order={JSON.parse(JSON.stringify(currentOrder))}
          disabled={closedOrCanceled}
          onClose={() => setShowOrderForm(false)}
        ></OrderForm>
      )}
    </Box>
  );
};

export default OrderDetail;

export function Detail({ title, value, className }: any) {
  if (value === null) return null;
  return (
    <Box className="OrderDetailItem">
      <Typography color="textSecondary" variant="body2">
        {title}
      </Typography>

      <Typography className={className} color="textSecondary" variant="body2">
        {value || "-"}
      </Typography>
    </Box>
  );
}

export function TelDetail({ title, value }: any) {
  if (value === null) return null;

  const tel = "+55" + (value || "").replace(/\D/g, "");

  const messageWhats = useStore.getState().settings?.whatsappOrderMessage?.trim();
  const urlencodedMessage = encodeURIComponent(messageWhats);

  return (
    <Box className="OrderDetailItem whats">
      <Typography color="textSecondary" variant="body2">
        {title}
      </Typography>

      <div className="whatsappDiv">
        <span>{value || "-"}</span>
        <a className="whatsappButton" href={`https://wa.me/${tel}?text=${urlencodedMessage}`} target="blank">
          <WhatsApp />
        </a>
      </div>
    </Box>
  );
}

export function ItemDetail({
  priceFormatted,
  quantity,
  id,
  totalPriceFormatted,
  product,
  isEditing,
  className,
  onRemove,
  onChangeQuantity,
  isRemoved,
  totalPrice,
  disabled,
  price,
  originalPrice,
  originalPriceFormatted,
  originalTotalPrice,
  originalTotalPriceFormatted,
}: any) {
  return (
    <Box className={"OrderDetailProduct " + className + " " + (isRemoved && "removed")}>
      <Box>
        <Box className="OrderDetail-img-wrapper">
          <Box
            className="OrderDetail-img"
            style={{ backgroundImage: `url(${product.image || placeHolderImage})` }}
          ></Box>
        </Box>
        <Typography color="textSecondary" variant="body2">
          <span style={{ display: "block" }}>{product.referenceCode ? `(${product.referenceCode})` : ""}</span>
          {product.name}
        </Typography>
      </Box>
      <Box>
        <Typography color="textSecondary" variant="body2">
          {quantity} x {isEditing ? formatPrice(price) : priceFormatted}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {isEditing ? formatPrice(totalPrice) : totalPriceFormatted}
        </Typography>
      </Box>

      <Box style={originalPrice ? { textDecoration: "line-through" } : { marginBottom: 0 }}>
        {originalPrice && (
          <>
            <Typography color="textSecondary" variant="body2">
              {quantity} x {isEditing ? formatPrice(originalPrice) : originalPriceFormatted}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {isEditing ? formatPrice(originalPrice * quantity) : originalTotalPriceFormatted}
            </Typography>
          </>
        )}
      </Box>

      {isEditing && (
        <>
          <Box>
            <div className={"MuiChip-root MuiChip-outlined"}>
              <RemoveCircleOutline
                className={quantity === 1 || disabled ? "disabled" : ""}
                onClick={() => {
                  if (disabled) return;
                  onChangeQuantity(id, quantity - 1);
                }}
              />
              <div>{quantity}</div>
              <AddCircleOutline
                className={disabled ? "disabled" : ""}
                onClick={() => {
                  if (disabled) return;
                  onChangeQuantity(id, quantity + 1);
                }}
              />
            </div>
          </Box>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            title="Delete Item"
            className={disabled ? "disabled ItemDeleteButton" : "ItemDeleteButton"}
            onClick={(e) => {
              if (disabled) return;
              onRemove(id, !isRemoved);
              e.preventDefault();
            }}
          >
            {isRemoved ? <Restore /> : <DeleteForeverOutlined />}
          </a>
        </>
      )}
    </Box>
  );
}

function SetDeliverySettings({ orderId, currentCourier, deliveryTime, disabled }: any) {
  const autoC = useRef(null);
  const couriers = useStore((state) => state.couriers);

  const setDialog = useStore((state) => state.setDialog);

  const [deliveryTime_, setDeliveryTime] = useState<any>([]);

  const defaultTime = useStore((state) => state.defaultTime);

  const [courier, setCourier] = useState<any>(null);
  const [saving, setSaving] = useState(false);
  const [hasDeliveryTime, setHasDeliveryTime] = useState(false);

  useEffect(() => {
    if (couriers.length && currentCourier) {
      const c = couriers.find((c: any) => c.id === currentCourier.id);
      setCourier(c);
    } else {
      setCourier(null);
    }

    if (deliveryTime) {
      setDeliveryTime(deliveryTime);
      setHasDeliveryTime(true);
    } else {
      setDeliveryTime(defaultTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(couriers), JSON.stringify(currentCourier), JSON.stringify(deliveryTime)]);

  useEffect(() => {
    if (!hasDeliveryTime) {
      setDeliveryTime(defaultTime);
    }
  }, [defaultTime, hasDeliveryTime]);

  const saveCourier = async () => {
    setSaving(true);
    try {
      await api.put(`/orders/delivery-settings/${orderId}`, {
        courier: courier?.id || null,
        deliveryTime: deliveryTime_,
      });
    } catch (error: any) {
      if (error.response.data.error.details) {
        setDialog({
          open: true,
          title: "Não foi possivel alterar dados de entrega",
          message: error.response.data.error.details.message,
          callbackConfirm: null,
        });
      }
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setDeliveryTime(newValue as number[]);
  };

  return (
    <section className="SectionDeliverySettings">
      <Box className="OrderDetailItem">
        <Typography color="textSecondary" variant="body2">
          Dados de entrega
        </Typography>
      </Box>

      <div
        style={{
          position: "relative",
        }}
      >
        <Autocomplete
          disablePortal
          ref={autoC}
          style={{ marginTop: "10px" }}
          options={couriers}
          getOptionLabel={(option: any) => `${option.name}`}
          value={courier}
          onChange={(_: any, newValue: any) => {
            setCourier(newValue);
          }}
          disabled={saving}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={saving}
              variant="outlined"
              className="AddTaskTextField"
              style={{ paddingBottom: 0 }}
              label="Entregador"
            />
          )}
        />
      </div>

      {disabled && !deliveryTime ? (
        <></>
      ) : (
        <Box
          style={{ marginTop: "20px", border: "solid 1px #c4c4c4" }}
          className="MuiInputBase-root MuiOutlinedInput-root MuiAutocomplete-inputRoot MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd"
        >
          <Typography
            variant="caption"
            style={{
              color: "rgb(117 117 117)",
              marginTop: "-19px",
              background: "white",
              padding: "0 4px",
            }}
          >
            Tempo para entrega (min, max)
          </Typography>
          <Slider
            value={deliveryTime_}
            step={5}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={5}
            max={300}
            disabled={disabled}
          />

          <Typography
            variant="body1"
            style={{
              background: "white",
              padding: "5px 4px 0",
            }}
          >
            de {deliveryTime_[0]}min à {deliveryTime_[1]}min
          </Typography>
        </Box>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Button disabled={saving} type="submit" variant="outlined" color="primary" onClick={saveCourier}>
          Salvar dados de entrega
        </Button>

        <CircularProgress
          size={saving ? 20 : 0}
          style={{
            marginLeft: saving ? "10px" : "0",
          }}
        />
      </div>
    </section>
  );
}
