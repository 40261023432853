import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import { BoardArea } from "./BoardArea/BoardArea";
import "./Board.css";
import OrderDetail from "./OrderDetail/OrderDetail";

type Board = {
  id: number;
};

export const BoardComponent = () => {
  const [board, setBoard] = useState<Board>(undefined as any);

  useEffect(() => {
    const buildBoard: Board = {
      id: 1,
    };
    setBoard(buildBoard);
  }, []);

  if (!board) return null;

  return (
    <Box className="BoarAreaWrapper">
      <OrderDetail />
      <BoardArea board={board} />
    </Box>
  );
};

export default Board;
