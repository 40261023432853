import create from "zustand";
import { api, removeBearerToken, setBearerToken } from "../config/api";

const user =
  localStorage.getItem("user") && localStorage.getItem("user") !== "undefined"
    ? JSON.parse(localStorage.getItem("user") || "")
    : null;

const jwt =
  localStorage.getItem("jwt") && localStorage.getItem("jwt") !== "undefined" ? localStorage.getItem("jwt") || "" : null;

if (jwt) {
  setBearerToken(jwt);
}

const defaultTime =
  localStorage.getItem("defaultTime") !== null ? JSON.parse(localStorage.getItem("defaultTime")) : [30, 60];

const historyHours =
  localStorage.getItem("historyHours") !== null ? JSON.parse(localStorage.getItem("historyHours")) : 3;

const beepVolume = localStorage.getItem("beepVolume") !== null ? JSON.parse(localStorage.getItem("beepVolume")) : 1;

const sounds = {
  "announcement-sound.mp3": () => new Audio(`/sounds/announcement-sound.mp3`),
  "bonus.mp3": () => new Audio(`/sounds/bonus.mp3`),
  "call-to-attention.mp3": () => new Audio(`/sounds/call-to-attention.mp3`),
  "cash-register-purchase.mp3": () => new Audio(`/sounds/cash-register-purchase.mp3`),
  "decide.mp3": () => new Audio(`/sounds/decide.mp3`),
  "fail.mp3": () => new Audio(`/sounds/fail.mp3`),
  "game-bonus.mp3": () => new Audio(`/sounds/game-bonus.mp3`),
  "game-level-complete.mp3": () => new Audio(`/sounds/game-level-complete.mp3`),
  "new-level.mp3": () => new Audio(`/sounds/new-level.mp3`),
  "soft-bells.mp3": () => new Audio(`/sounds/soft-bells.mp3`),
};

if (Notification.permission !== "granted" && Notification.permission !== "denied") {
  // We need to ask the user for permission
  Notification.requestPermission();
}

export const beepAudio = new Audio(`/sounds/beep.mp3`);
let beepContext = null;
let beepGainNode = null;
let beepSource = null;

let playCount = 0;

function eventListenerBeep() {
  beepAudio.currentTime = 0;
  playCount++;

  if (playCount === 3) {
    beepAudio.pause();
    playCount = 0;
  } else {
    beepAudio.play();
  }
}

beepAudio.addEventListener("ended", eventListenerBeep);

let context = null;
let gainNode = null;
let source = null;

function playSound(value, volume, title = "") {
  //@ts-ignore
  const soundValue = sounds[value];
  if (!soundValue) {
    return;
  }

  if (volume > 0) {
    new Notification(title, {
      body: "Novo pedido",
      silent: true,
    });
  }

  let audio = soundValue();
  if (!context) {
    context = new AudioContext();
    gainNode = context.createGain();
    source = context.createMediaElementSource(audio);
    source.connect(gainNode);
    gainNode.connect(context.destination);
  }

  audio.currentTime = 0;
  gainNode.gain.value = volume > 9 ? 9 : volume;
  audio.play();
}

export function fnIntervalMissUpdates() {
  api.get(`/orders/check-miss-updates?timestamp=${useStore.getState()?.lastTimestamp || ""}`).then((data) => {
    useStore.getState()?.checkMissUpdates(data);
  });
}

export function playBeep() {
  const cards = useStore.getState()?.board.columns[0].cards;

  if (cards.length === 0) {
    return;
  }

  if (!useStore.getState().hasInteracted) return;

  let shoudPlay = false;
  const now = new Date().getTime();
  const moreThanOneMinute = 60 * 1000;
  for (let i = 0; i < cards.length; i++) {
    if (cards[i].content.opened === false) {
      const createdAt = new Date(cards[i].content.createdAt).getTime();

      if (now - createdAt > moreThanOneMinute) {
        shoudPlay = true;
        break;
      }
    }
  }

  if (!shoudPlay) return;

  if (!beepContext) {
    beepContext = new AudioContext();
    beepGainNode = beepContext.createGain();
    beepSource = beepContext.createMediaElementSource(beepAudio);
    beepSource.connect(beepGainNode);
    beepGainNode.connect(beepContext.destination);
  }

  let vol = useStore.getState().beepVolume;
  vol = vol > 9 ? 9 : vol;
  beepGainNode.gain.value = vol;

  beepAudio.play();
}

export const useStore = create((set, get) => ({
  user,
  jwt,
  isValidatingConnection: true,

  hasInteracted: false,
  board: { columns: [] },
  orders: [],
  currentOrder: null,
  products: [],
  couriers: [],
  settings: {},
  isSearchingByHours: false,
  isOpen: false,

  dialog: { open: false, title: "", message: "", callbackConfirm: null },

  defaultTime,
  historyHours,
  onlineIndicator: false,
  lastTimestamp: null,
  paymentMethods: [],

  beepVolume,

  setBeepVolume: (beepVolume) => {
    localStorage.setItem("beepVolume", JSON.stringify(beepVolume));
    set({ beepVolume });
  },

  setTimeStamp: (timestamp) => {
    set({ lastTimestamp: timestamp });
  },

  checkMissUpdates: ({ data }) => {
    set({ lastTimestamp: data.timestamp });

    if (data.orders && data.orders?.length > 0) {
      const currentOrders = get().orders;

      let delayTimer = 0;
      data.orders.forEach((order) => {
        if (!currentOrders.find((o) => o.id === order.id)) {
          setTimeout(() => {
            get().addOrder(order);
          }, delayTimer);
          delayTimer += 500;
        } else {
          get().updateOrder(order.id, order);
        }
      });
    }
  },

  setOnlineIndicator: (onlineIndicator) => {
    set({ onlineIndicator });
  },

  setHasInteracted: (hasInteracted) => {
    set({ hasInteracted });
    playSound(get()?.settings?.orderSound, 0);
  },

  setIsSearchingByHours: (isSearchingByHours) => {
    set({ isSearchingByHours });
  },

  updateDefaultTime: (defaultTime) => {
    localStorage.setItem("defaultTime", JSON.stringify(defaultTime));
    set({ defaultTime });
  },

  setUser: ({ user, jwt, refreshToken }) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("jwt", jwt);
    localStorage.setItem("refreshToken", refreshToken);

    set({ user, jwt });
  },

  setValidatinConnection: (status) => {
    set({ isValidatingConnection: status });
  },

  setHistoryHours: (historyHours) => {
    localStorage.setItem("historyHours", JSON.stringify(historyHours));
    set({ historyHours });
  },

  logout: () => {
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    localStorage.removeItem("refreshToken");
    set({
      user: null,
      jwt: null,
      isValidatingConnection: true,

      board: { columns: [] },
      orders: [],
      currentOrder: null,
      products: [],
      settings: {},

      paymentMethods: [],
    });
    removeBearerToken();
  },

  setBoard: (_columns) => {
    const mappedcolumns = _columns.map((column) => {
      column.cards = column.cards || [];
      return column;
    });

    const firstElement = mappedcolumns.shift();
    mappedcolumns.push(firstElement);

    set({ board: { columns: mappedcolumns } });
  },

  updateBoard: (board) => {
    set({ board });
  },

  setIsOpen: (isOpen) => {
    set({ isOpen });
  },

  setOrders: (orders) => {
    let board = get().board;
    board.columns = board.columns.map((column) => {
      column.cards = [
        ...orders.filter((order) => order.status === column.id).map((order) => ({ content: order, id: order.id })),
      ];
      return column;
    });

    set({ orders, board: { ...board } });
  },

  setCurrentOrder: (currentOrder) => {
    if (currentOrder && !currentOrder.opened) {
      api
        .put(`/orders/open/${currentOrder.id}`)
        .then((res) => {
          setTimeout(() => {
            set({ currentOrder: { ...currentOrder, opened: true } });
          }, 100);
        })
        .catch((err) => {
          get().setDialog({
            open: true,
            title: "Não foi possivel abrir o pedido",
            callbackConfirm: null,
          });
        });
    } else {
      set({ currentOrder });
    }
  },

  updateOrder: (orderId, data, replace = false) => {
    let orders = [...get().orders];
    const orderIndex = orders.findIndex((order) => order.id === orderId);

    if (orderIndex !== -1) {
      orders[orderIndex] = { ...orders[orderIndex], ...data };
    }

    let board = get().board;

    let updatedOrder;

    for (let column of board.columns) {
      const cardIndex = column.cards.findIndex((card) => card.id === orderId);

      if (cardIndex !== -1) {
        updatedOrder = { ...column.cards[cardIndex] };
        updatedOrder.content = { ...updatedOrder.content, ...data };

        if (data.status && !replace) {
          column.cards.splice(cardIndex, 1);
          break;
        }

        column.cards[cardIndex] = updatedOrder;

        break;
      }
    }

    if (data.status && !replace) {
      const newColumnIndex = board.columns.findIndex((column) => column.id === data.status);
      if (newColumnIndex !== -1 && updatedOrder) {
        updatedOrder.content.statusName = board.columns[newColumnIndex].title;
        board.columns[newColumnIndex].cards.push(updatedOrder);
      }
    }

    let updatedState;

    if (get().currentOrder !== undefined && get().currentOrder?.id === orderId) {
      if (updatedOrder) {
        orders[orderIndex].statusName = updatedOrder.content.statusName;
      }
      updatedState = { currentOrder: orders[orderIndex], orders: [...orders], board: { ...board } };
    } else {
      updatedState = { orders: [...orders], board: { ...board } };
    }

    set(updatedState);
  },

  addOrder: (order) => {
    let orders = [...get().orders];

    const isAlreadyInserted = orders.find((o) => o.id === order.id);

    if (isAlreadyInserted) {
      return;
    }

    orders.push(order);

    let board = get().board;
    board.columns = board.columns.map((column) => {
      if (column.id === order.status) {
        column.cards.push({ content: order, id: order.id });
      }
      return column;
    });

    if (get().settings?.orderSound && get().settings?.orderSoundVolume > 0) {
      playSound(get().settings?.orderSound, get().settings?.orderSoundVolume, get().settings?.name);
    }

    set({ orders: [...orders], board: { ...board } });
  },

  setPaymentMethods: (paymentMethods) => {
    const mappedPaymentMethods = paymentMethods.data.map((paymentMethod) => ({
      id: paymentMethod.id,
      name: paymentMethod.attributes.name,
      isMoney: paymentMethod.attributes.isMoney,
    }));

    set({ paymentMethods: mappedPaymentMethods });
  },

  setProducts: (products) => {
    set({ products });
  },

  setCouriers: (couriers) => {
    set({ couriers });
  },

  setSettings: (settings) => {
    const mappedSettings = {
      ...settings.data[0].attributes,
    };
    set({ settings: mappedSettings, isOpen: mappedSettings.isOpen });
  },

  setDialog: (dialog) => {
    set({ dialog });
  },

  getStatus: (status) => {
    const board = get().board;
    const findStatus = board.columns.find((column) => column.id === status);
    return findStatus;
  },
}));
