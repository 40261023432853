import { Box, Typography, Button, CircularProgress, Popover } from "@material-ui/core";
import { useState } from "react";
import { useStore } from "../../../store";
import { api } from "../../../config/api";
import { printTemplate } from "../../../config/print";

export default function AcceptAll() {
  const board = useStore((state) => state.board);
  const setDialog = useStore((state) => state.setDialog);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [acceptingOrder, setAcceptingOrder] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-all" : undefined;

  const delayBetweenOrders = 1 * 1000;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const acceptOrder = async () => {
    const ordersCreated = board?.columns?.find(({ order }) => order === 0);

    if (!ordersCreated?.cards.length) {
      setDialog({
        open: true,
        title: "",
        message: "Nenhum pedido para aceitar",
        callbackConfirm: null,
      });

      handleClose();

      return;
    }

    const ids = ordersCreated.cards.map(({ id }) => id).sort((a, b) => a - b);
    //make a copy of the orders to print
    const ordersToPrint = [...ordersCreated.cards];

    const successOrders: any[] = [];

    setAcceptingOrder(true);
    const promisses = ids.map((id, index) => {
      const p = new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          api
            .put(`/orders/accept/${id}`)
            .then(() => {
              successOrders.push(ordersToPrint.find((order) => order.id === id)?.content);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }, delayBetweenOrders * index);
      });

      return p;
    });

    Promise.all(promisses)
      .then(() => {
        handleClose();
        handlePrint(successOrders);
      })
      .finally(() => {
        setAcceptingOrder(false);
      });
  };

  const handlePrint = (orders) => {
    const ticket = {
      header: useStore.getState().settings.ticketHeader || "",
      footer: useStore.getState().settings.ticketFooter || "",
      copies: useStore.getState().settings.ticketCopies,
    };

    let newWindow = window.open();
    newWindow?.document.write(printTemplate(ticket, orders));
    newWindow?.document.close();
    newWindow?.focus();
    newWindow?.print();
    newWindow?.close();
  };

  return (
    <>
      <Button variant="outlined" color="secondary" onClick={handleClick}>
        Aceitar todos
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {}}
        style={{ width: "400px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          style={{
            padding: 20,
          }}
        >
          <Typography variant="button">Aceitar todos os pedidos ?</Typography>
          <Typography variant="body1">
            Todos os pedidos serão alterados para o próximo status e os clientes serão notificados
          </Typography>

          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Button variant="text" color="default" disabled={acceptingOrder} onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={acceptingOrder}
              onClick={acceptOrder}
              style={{
                marginLeft: "10px",
              }}
            >
              Aceitar
            </Button>
            <CircularProgress
              size={acceptingOrder ? 20 : 0}
              style={{
                marginLeft: acceptingOrder ? "10px" : "0",
              }}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
}
