import React, { useEffect } from "react";

import { CircularProgress, Divider } from "@material-ui/core";

import { BoardComponent } from "../../components/Boards/Board";
import { Toolbar } from "../../components/Toolbar/Toolbar";
import { useStore } from "../../store";
import "./BoardPage.css";
import { api } from "../../config/api";
import { useHistory } from "react-router-dom";

export function BoardsPage() {
  const setValidatinConnection = useStore((state) => state.setValidatinConnection);
  const isValidatingConnection = useStore((state) => state.isValidatingConnection);
  const setBoard = useStore((state) => state.setBoard);
  const settings = useStore((state) => state.settings);

  const logout = useStore((state) => state.logout);

  const history = useHistory();

  useEffect(() => {
    if (isValidatingConnection) {
      api.get("/users/me").then(() => {
        api.get("/board").then((response) => {
          setBoard(response.data);
          setValidatinConnection(false);
        });
      });
    }
  }, [history, isValidatingConnection, logout, setBoard, setValidatinConnection]);

  if (isValidatingConnection) {
    return (
      <div className="BoardLoading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Toolbar companyName={settings.name} />
      <Divider />
      <BoardComponent />
    </div>
  );
}
